<template>
    <TaskPageWrapper :task="task" :instructions="taskPageInstructions" @update="loadTask">
        <TaskPageDocumentFileArea title="Document" color="blue" icon="document" :document-name="task.file_name"
                                  @linkClicked="toggleDocumentPreview">
            <LoadingButton color="primary" variant="outline" :loading="loadingTaskFileDownload"
                           :aria-disabled="loadingTaskFileDownload" @click="downloadTaskFile">
                Download
            </LoadingButton>
            <LoadingButton v-if="isTaskSubContractor && isPendingAcknowledgement" color="primary" :loading="loading"
                           :disabled="loading" @click="handleAcknowledge">
                Acknowledge
            </LoadingButton>
        </TaskPageDocumentFileArea>
    </TaskPageWrapper>
    <ConfirmDialog ref="confirmDialog"/>
    <DocumentPreviewer :visible="documentPreviewer" type="tasks" :type-id="task?.id" route="preview-file"
                       previewer="third-party" :document-name="task?.file_name" :extension="documentPreviewerExtension"
                       @closed="toggleDocumentPreview"/>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import TaskPageWrapper from "@/components/TaskPages/TaskPageWrapper.vue";
import Tasks from "@/api/v2/endpoints/Tasks";
import taskHelper from "@/mixin/taskHelper";
import documentDownload from "@/mixin/documentDownload";
import TaskPageDocumentFileArea from "@/components/TaskPages/TaskPageDocumentFileArea.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import DocumentPreviewer from "@/components/Documents/DocumentPreviewer.vue";
import fileExtension from "@/mixin/fileExtension";

export default {
    components: {
        DocumentPreviewer,
        LoadingButton, TaskPageDocumentFileArea,
        TaskPageWrapper,
        ConfirmDialog,
    },
    mixins: [taskHelper, documentDownload, fileExtension],
    data() {
        return {
            loading: false,
            documentPreviewer: false,
            documentPreviewerExtension: null,
            subTaskPageInstructions: [
                'Please review and acknowledge the document below.'
            ],
            gcTaskPageInstructions: [
                'Your sub has received the notification to complete this task.'
            ],
            taskId: null,
            task: null,
        }
    },
    created() {
        this.taskId = this.$route.params.task_id;
        this.loadTask();
    },
    methods: {
        loadTask() {
            Tasks
                .show(this.taskId, {
                    task_data: this.taskPageData.toString(),
                    with_external_company: 'id,name',
                    with_internal_company: 'id,name',
                    with_company_assigner_users: 'id,full_name',
                    with_company_signer_users: 'id,full_name',
                    with_task_approval: 'all',
                    with_project: 'name',
                })
                .then(response => {
                    this.task = response.data;
                    this.documentPreviewerExtension = this.getFileExtension(this.task.file_name);
                });
        },
        handleAcknowledge() {
            this.$refs.confirmDialog
                .confirm({
                    text: 'Are you sure you want to acknowledge the task? This action can not be undone.',
                    onlyConfirmButton: true,
                    confirmText: 'Confirm',
                    reverse: true,
                })
                .then(confirm => {
                    if (confirm) this.acknowledgeTask();
                });
        },
        acknowledgeTask() {
            this.toggleLoading();

            Tasks
                .acknowledge(this.task.id)
                .then(() => {
                    this.toast('info', 'Document acknowledged successfully!');
                    this.loadTask();
                })
                .finally(() => this.toggleLoading());
        },
        toggleDocumentPreview() {
            this.documentPreviewer = !this.documentPreviewer;
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
}
</script>
